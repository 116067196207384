"use strict";

// If you are using JavaScript/ECMAScript modules:
import { Application } from "@hotwired/stimulus";
import ApplicationController from "./controllers/application_controller";
import FaqController from "./controllers/faq_controller";
import FilterController from "./controllers/filter_controller";
import LightboxController from "./controllers/lightbox_controller";
import PwdController from "./controllers/pwd_controller";

import Glide from "@glidejs/glide";

// import BSFachbetreuungController from "./controllers/bs_fachbetreuung_controller";

if (window.stimulus == undefined) {
  window.Stimulus = Application.start();
  console.log("started Stimulus application…");
}

Stimulus.register("faq", FaqController);
Stimulus.register("filter", FilterController);
Stimulus.register("lightbox", LightboxController);
Stimulus.register("pwd", PwdController);

function slick_init() {
  let slick_images = document.querySelector(".schulimages");
  let titel_diashow = document.querySelector(".titel_diashow");
  if (slick_images != undefined) {
    new Glide(".schulimages", { autoplay: 4000 }).mount();
  }
  if (titel_diashow != undefined) {
    new Glide(".titel_diashow", { autoplay: 4000 }).mount();
  }
}

function confirm_init() {
  let confirmables = document.querySelectorAll(".confirm");
  for (var i = 0; i < confirmables.length; i++) {
    confirmables[i].addEventListener("click", function (e) {
      if (!confirm("Sind Sie sicher?")) {
        e.preventDefault();
      }
    });
  }
}
function print_button_init() {
  let print_buttons = document.querySelectorAll(".print-button");
  for (var i = 0; i < print_buttons.length; i++) {
    print_buttons[i].addEventListener("click", function () {
      return window.print();
    });
  }
}

function external_links_init() {
  let external_links = document.querySelectorAll("a[href*=http]");
  for (var i = 0; i < external_links.length; i++) {
    let href = external_links[i].getAttribute("href");
    if (href == undefined) continue;
    if (href.startsWith("https://schule-oberfranken.de")) continue;
    console.log("updating external link: " + href);
    if (href.startsWith("http://")) {
      external_links[i].setAttribute(
        "href",
        href.replace(/http:\/\//, "https://"),
      );
    }

    external_links[i].setAttribute("target", "_blank");
  }
}

document.addEventListener(
  "DOMContentLoaded",
  function () {
    confirm_init();
    slick_init();
    print_button_init();
    external_links_init();
  },
  false,
);

// Stimulus.register("bsfachbetreuung", BSFachbetreuungController);

/*


function filter_teasers(input, filter_target, filter_tag) {
  var ival = document.querySelector(input).val();
  if (!filter_tag) {
    filter_tag = "div";
  }
  if (!ival) {
    let
      jQuery(filter_target).find(filter_tag).show();
    return true;
  }
  jQuery(filter_target).find(filter_tag + ":not(:Contains(" + ival + "))").slideUp();
  jQuery(filter_target).find(filter_tag + ":Contains(" + ival + ")").slideDown();
}



function print_button_init() {
  jQuery('.print-button').on('click', function() {
    return window.print();
  });
}

function link_target_fix() {
  jQuery(document.links).filter(function() {
    return this.hostname != window.location.hostname;
  }).attr('target', '_blank');
}

function init_page() {
  link_target_fix();
  slick_init();
  confirm_init();
  faq_init();
  collapsible_init();
  print_button_init();
  lightbox.init();
  if (typeof tinymce4_init === "function") {
    tinymce4_init();
  }
  pwd_protect_init();
}

jQuery(document).ready(function() {
  init_page();
});
*/

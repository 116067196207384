import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async doFetch(url, method, body, headers = false) {
    if (!headers) {
      headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        authenticity_token: this.getCsrfToken(),
      };
    }
    const response = await fetch(url, {
      method: method,
      headers: headers,
      body: body,
    });
    return response;
  }

  getCsrfToken() {
    return "";
  }

  getParent(tester, test_criterium, test_class = "class", test_value) {
    while (tester != null) {
      if (test_class == "tagname") {
        // console.log("test tagname: " + tester.tagName.toUpperCase())
        if (tester.tagName == test_criterium.toUpperCase()) {
          return tester;
        }
      }
      if (test_class == "class") {
        if (
          tester.classList != null &&
          tester.classList.contains(test_criterium)
        ) {
          return tester;
        }
      }
      if (test_class == "dataset") {
        if (
          test_criterium in tester.dataset === true &&
          test_value == undefined
        ) {
          return tester;
        }
        if (
          test_criterium in tester.dataset === true &&
          test_value == tester.dataset[test_criterium]
        ) {
          return tester;
        }
      }
      tester = tester.parentElement;
    }
    return false;
  }

  ajax_url(action, controller) {
    let ajax_loc;
    let article_id = 322;

    ajax_loc =
      "index.php?article_id=" +
      article_id +
      "&action=" +
      action +
      "&controller=" +
      controller;
    // console.log("ajax_loc: " + ajax_loc);
    return ajax_loc;
  }
  getYformFormgroup(name) {
    let e = document.querySelector(`div.form-group[id*="${name}"]`);
    if (e == undefined || e == false) return false;
    return e;
  }
  getYformInput(name) {
    let formgroup = this.getYformFormgroup(name);
    if (!formgroup) return false;
    let e = formgroup.querySelector("input, select, textarea");
    if (e == undefined || e == false) return false;
    return e;
  }
  loadControllerAction(controller, action) {
    let l = window.location;
    let path = l.pathname;
    let query = l.search;

    var params = [];
    let article_id = query.match(/article_id=(\d+)/);
    if (article_id) {
      params.push("article_id=" + article_id[1]);
    }
    params.push("action=" + action);
    params.push("controller=" + controller);

    let nl = `${l.protocol}//${l.hostname}${l.pathname}?${params.join("&")}`;
    // console.log("nl " + nl);
    window.location.assign(nl);
  }

  showmsg(msg, timeout = 200) {
    let toastElement = document.getElementById("msgToast");
    toastElement.querySelector(".toast-body").innerHTML = msg;
    toastElement.classList.remove("d-none");
    toastElement.style.opacity = 1;
    console.log("showmsg() " + msg);
    this.fadeout(toastElement, timeout);
  }
  fadeout(el, timeout = 200) {
    this.intervalID = setInterval(this.hide(el), timeout);
  }
  hide(el) {
    return function () {
      var opacity = Number(
        window.getComputedStyle(el).getPropertyValue("opacity"),
      );

      if (opacity > 0) {
        opacity = opacity - 0.1;
        el.style.opacity = opacity;
      } else {
        clearInterval(this.intervalID);
      }
    }.bind(this);
  }
  print() {
    let elem = document.querySelector(".printable");
    var mywindow = window.open("", "PRINT", "height=400,width=600");

    mywindow.document.outerHTML = "";
    mywindow.document.write(
      "<html><head><title>" + document.title + "</title>",
    );
    mywindow.document.write(
      '<link rel="stylesheet" href="/assets/addons/sprengel_app/gutenberg.min.css" media="print">',
    );
    mywindow.document.write(
      '<link rel="stylesheet" href="/assets/addons/sprengel_app/gutenberg_oldstyle.min.css" media="print">',
    );
    mywindow.document.write("</head><body >");
    mywindow.document.write(elem.innerHTML);
    mywindow.document.write("</body></html>");

    // mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    // mywindow.close();

    return true;
  }
}
